.card_subjects {
  border-radius: 5px;
}

.card_subjects__body {
  height: 8rem;
  padding: 15px 5px;
}

.card_subject__descriptions {
  flex: 0.9;
}

.card_subject__business_name {
  font-size: 1em;
  font-weight: bold;
}

.card_subject__info {
  font-size: 0.8em;
}
