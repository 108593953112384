.container-nav {
  position: sticky;
  top: 0;
  width: auto;
  font-weight: 600 !important;
  background-color: white;

  margin-top: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
}

.toggler-user-menu {
  padding: 3px !important;
  line-height: 15px;
  border-radius: 5px;
  background-color: white;
  border: none;
  color: black;
}

.button_menu {
  background-color: white;
  color: black;
}

.button_menu:hover,
.toggler-user-menu:hover {
  background-color: black;
  color: white;
}

.container-nav .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: black;
}

.container-nav a {
  color: black;
  text-decoration: none;
  background-color: transparent;
}

.container-nav a:hover {
  color: #7166f9;
  text-decoration: none;
  background-color: transparent;
}
