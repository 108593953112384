.sequence-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circles-sequence {
  display: flex;
  justify-content: center;
  align-items: center;
  width: initial;
  gap: 1px;
}

.circles-sequence span {
  background: black;
  color: white;
  border-radius: 100%;
  font-size: 10px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
}
