.filter_button {
  background-color: var(--primarybg);
  color: var(--primary);
  font-weight: bold;
}

.filter_button__counter {
  padding-left: 6px;
  border-left: 2px solid var(--primary);
  margin-left: 8px;
}

.filter_button:hover .filter_button__counter {
  border-color: white;
}

.filter_button:focus .filter_button__counter {
  border-color: white;
}
