.sidebar_close_button {
  color: black;
}

.sidebar {
  box-shadow: rgba(0, 0, 0, 0.03) 1px 1px 4px !important;
}

.sidebar .nav-link {
  height: 60px;
  display: flex;
  align-items: center;
  vertical-align: middle;
  padding: 1rem 1rem;
  color: black;
  cursor: pointer;
}

.sidebar .nav-link > span {
  margin-left: 20px;
}

.sidebar .nav-link > svg {
  color: #d0d0d0;
}

.sidebar .nav-link.active {
  background-color: #f8f8f9;
  color: black;
  border-right: 2px solid black;
}

.sidebar .nav-link.active > svg {
  color: black;
}

.sidebar .nav-item > * {
  transition: transform 0.25s ease;
}

.sidebar .nav-item:hover > * {
  transform: translateX(5px);
}

.sidebar .collapse-menu {
  background: black;
  margin: 10px;
  border-radius: 0.5rem;
  color: white;
  list-style: none;

  padding: 10px;
}

.sidebar .collapse-menu .nav-link {
  border-radius: 0.25rem;
  color: white;
  margin-bottom: 3px;
}

.sidebar .collapse-menu .nav-link > svg {
  border-radius: 0.25rem;
  color: white;
}

.sidebar .collapse-menu .nav-link.active {
  background-color: #f8f8f9;
  color: black;
  border-right: none;
}

.sidebar .collapse-menu .nav-link.active > svg {
  color: black;
}
