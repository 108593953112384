.card__deliveries {
  z-index: 10000;
  background-color: white;
  border-radius: 10px;
}

.card__stats {
  padding: 5px 5px;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.card__stats span.h1 {
  color: black;
  font-size: 1.3rem;
  font-weight: bolder;
  margin: 1rem 0;
}

.card__stats h6 {
  font-size: 0.67rem;
  color: rgb(98, 98, 98);
  font-weight: normal;
}

.icon__container {
  background-color: #8e8e8e2b;
  border-radius: 100%;
  padding: 5px;
  color: black;
  margin-left: 5px;
}

.card__selection {
  background-color: #8e8e8e2b;
  color: black;
  padding: 1em;
  border-radius: 5px;
}

.card__selection h6 {
  font-weight: bold;
  margin-bottom: 0;
}

.vertical-line {
  height: 2em;
  right: 50%;
  width: 50%;
  border-right: 1px dashed rgba(70, 70, 70, 0.28);
  margin-top: 5px;
  margin-bottom: 5px;
}

.card__selection table th {
  color: black;
  border: none;
  font-size: 1em;
}

.card__selection table tr {
  border-bottom: 1px solid #cbcbcb;
}

.card__selection table td {
  border: none;
}

.card__selection span {
  border: none;
}

.card__deliveries table th {
  color: black;
  border: none;
  font-size: 1em;
}

.card__deliveries table tr {
  border-bottom: 1px solid #cbcbcb;
}

.card__deliveries table td {
  border: none;
}

button.bg-success-secondary {
  background-color: #28a74640;
  border: none;
  color: #28a745;
  font-weight: bold;
}

button.bg-primary-secondary {
  background-color: #7066f938;

  color: #7166f9;
  font-weight: bold;
}

.tab-menu {
  position: absolute;
  bottom: 0;
  border: none;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  border-top: 1px dashed rgba(70 70 70 0.28);
  padding-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: white;
}

.tab-menu li {
  margin-bottom: 5px !important;
}

.tab-menu li > a.nav-link {
  border-radius: 5px;
  border: none;
  color: black;
  margin-left: 3px;
  margin-right: 3px;
  background-color: #8e8e8e2b;
}

.tab-menu li > a.nav-link.active {
  border: 1px solid black;
  background-color: black;
  color: white;
}
