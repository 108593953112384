@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*
primary-color: var(--primary)
*/
body,
html {
  height: 100%;
}

:root {
  --primarybg: #7166f933;
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #7166f9;
  --secondary: #6c757d;
  --success: #4caf50;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

body {
  background-color: #f8f8f9;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: black;
  font-size: 14px;
}

#root {
  height: 100%;
}

.page-link {
  color: #7166f9;
  color: var(--primary);
}

.card {
  border: none;
  border-radius: 5px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
}

.card-header,
.card-footer {
  background-color: white;
  border: none;
  border-radius: 5px !important;
  padding: 1rem;
}

.card-body {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

h1,
h2,
h3,
h4,
h4,
h5,
h6 {
  font-weight: 700;
}

.btn {
  border-radius: 5px;
  padding: 0.75rem 0.75rem;
  font-weight: bold;
}

.btn-primary {
  color: #fff;
  background-color: #7166f9;
  background-color: var(--primary);
  border: none;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #332f63;
  border-color: #332f63;
}

.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #332f63;
  border-color: #332f63;
}

.btn-primary:hover {
  color: #fff;
  background-color: #4d47a7;
  border: none;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #7166f9;
  border-color: var(--primary);
  outline: 0;
  box-shadow: none;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0.3rem 0.1rem rgba(0, 0, 0, 0.11);
}

body.modal-open #the-content {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
}

.btn>span,
.dropdown-item>span {
  margin-left: 0.5rem;
  font-size: 14px;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #7166f9;
  background-color: var(--primary);
}

.edit-buttons {
  padding: 0.4rem 0.4rem;
  line-height: 0;
  margin-right: 5px;
}

tr {
  border-bottom: 1px solid #dee2e6;
}

td,
th {
  white-space: nowrap;
  font-size: 0.8rem;
  border: 1px solid #dee2e6;
}

td:last-child {
  border: none;
}

td:first-child {
  border: none;
}

th:first-child {
  border-left: none;
}

th:last-child {
  border-right: none;
}

th {
  border-bottom: none;
}

input.form-control.table-input {
  border: none;
  border-bottom: 1px solid black;
  border-radius: 0px;
  background: #0000000d;
}

input.form-control.table-input:focus {
  border-bottom: 1px solid #7166f9;
  border-bottom: 1px solid var(--primary);
}

.table td {
  padding: 0.5rem;
}

.table td,
.table th {
  border-top: none;
}

.btn-link:focus {
  outline: 0;
  box-shadow: none;
}

.btn-link {
  padding: 0px;
}

.table td .btn-link {
  font-size: 0.8rem;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(128, 128, 128, 0.404);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: black;
}

.breadcrumb {
  background-color: transparent;
  margin-bottom: 0;
}

a,
.btn-link {
  color: #7166f9;
  color: var(--primary);
}

a:hover,
.btn-link:hover {
  color: #4640a0;
}

.table_col tr {
  border: none;
}

.table_col {
  -webkit-flex: 0.5 1;
          flex: 0.5 1;
}

.table_col td {
  white-space: normal;
  padding: 5px 10px;
  vertical-align: top;
}

.table_col td:nth-child(2n) {
  width: 60%;
  border-right: 0.5px solid rgba(139, 139, 139, 0.432);
}

.table_col td div.form-group {
  margin-bottom: 0;
}

.table_col td .form-group {
  margin-bottom: 0;
}

.table_col td .form-control {
  min-height: 1.8rem;
}

.table_col_4 {
  -webkit-flex: 0.5 1;
          flex: 0.5 1;
}

.table_col_4 td {
  white-space: normal;
  padding: 5px 10px;
  vertical-align: top;
  border: 0;
}

.table_col_4 td:nth-child(2n) {
  border-right: 0.5px solid rgba(139, 139, 139, 0.432);
}

.table_col_4 tr {
  white-space: normal;
  padding: 5px 10px;
  vertical-align: top;
  border: 0;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: black;
}

.dropdown-menu.show {
  width: auto !important;
}

.card_header_primary {
  background: #7166f926;
  color: #7166f9;
  color: var(--primary);
}

.card_header_grey {
  background: #e4e4e4;
}

.alert-primary {
  color: #7d73f9;
  font-weight: bold;
  background-color: #7066f92f;
  border: none;
}

.modal-header {
  border-bottom: none;
}

.react-datepicker-wrapper {
  width: 100%;
}

.modal-content {
  border: none;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.form-control {
  padding: 0.375rem 0.75rem;
  font-size: inherit;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #7166f9;
  background-color: var(--primary);
  border-color: #7166f9;
  border-color: var(--primary);
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: calc(100% - 1rem);
    margin: 1.75rem auto;
  }
}

.trasparent-bg {
  background: transparent !important;
}
.card__deliveries {
  z-index: 10000;
  background-color: white;
  border-radius: 10px;
}

.card__stats {
  padding: 5px 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: white;
}

.card__stats span.h1 {
  color: black;
  font-size: 1.3rem;
  font-weight: bolder;
  margin: 1rem 0;
}

.card__stats h6 {
  font-size: 0.67rem;
  color: rgb(98, 98, 98);
  font-weight: normal;
}

.icon__container {
  background-color: #8e8e8e2b;
  border-radius: 100%;
  padding: 5px;
  color: black;
  margin-left: 5px;
}

.card__selection {
  background-color: #8e8e8e2b;
  color: black;
  padding: 1em;
  border-radius: 5px;
}

.card__selection h6 {
  font-weight: bold;
  margin-bottom: 0;
}

.vertical-line {
  height: 2em;
  right: 50%;
  width: 50%;
  border-right: 1px dashed rgba(70, 70, 70, 0.28);
  margin-top: 5px;
  margin-bottom: 5px;
}

.card__selection table th {
  color: black;
  border: none;
  font-size: 1em;
}

.card__selection table tr {
  border-bottom: 1px solid #cbcbcb;
}

.card__selection table td {
  border: none;
}

.card__selection span {
  border: none;
}

.card__deliveries table th {
  color: black;
  border: none;
  font-size: 1em;
}

.card__deliveries table tr {
  border-bottom: 1px solid #cbcbcb;
}

.card__deliveries table td {
  border: none;
}

button.bg-success-secondary {
  background-color: #28a74640;
  border: none;
  color: #28a745;
  font-weight: bold;
}

button.bg-primary-secondary {
  background-color: #7066f938;

  color: #7166f9;
  font-weight: bold;
}

.tab-menu {
  position: absolute;
  bottom: 0;
  border: none;
  left: 0;
  right: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  border-top: 1px dashed rgba(70 70 70 0.28);
  padding-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: white;
}

.tab-menu li {
  margin-bottom: 5px !important;
}

.tab-menu li > a.nav-link {
  border-radius: 5px;
  border: none;
  color: black;
  margin-left: 3px;
  margin-right: 3px;
  background-color: #8e8e8e2b;
}

.tab-menu li > a.nav-link.active {
  border: 1px solid black;
  background-color: black;
  color: white;
}

.sequence-icon {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.circles-sequence {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: auto;
  width: initial;
  grid-gap: 1px;
  gap: 1px;
}

.circles-sequence span {
  background: black;
  color: white;
  border-radius: 100%;
  font-size: 10px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.Filter_filter_button__3Zbgu {
  background-color: var(--primarybg);
  color: var(--primary);
  font-weight: bold;
}

.Filter_filter_button__counter__yjM_b {
  padding-left: 6px;
  border-left: 2px solid var(--primary);
  margin-left: 8px;
}

.Filter_filter_button__3Zbgu:hover .Filter_filter_button__counter__yjM_b {
  border-color: white;
}

.Filter_filter_button__3Zbgu:focus .Filter_filter_button__counter__yjM_b {
  border-color: white;
}

.Pill_pill__2ZA4Z {
  border: 2px solid var(--primary);
  color: var(--primary);
  padding: 5px 15px;
  border-radius: 10rem;
  margin-right: 10px;
  font-size: 0.8rem;
}

.Pill_pill__2ZA4Z.Pill_success__3AHnl {
  border: 2px solid var(--success);
  color: var(--success);
}

.Pill_pill__2ZA4Z.Pill_active__3tTm8 {
  background-color: var(--primary);
  color: white;
}

.card__deliveries {
  z-index: 10000;
  background-color: white;
  border-radius: 10px;
}

.card__stats {
  padding: 5px 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: white;
}

.card__stats span.h1 {
  color: black;
  font-size: 1.3rem;
  font-weight: bolder;
  margin: 1rem 0;
}

.card__stats h6 {
  font-size: 0.67rem;
  color: rgb(98, 98, 98);
  font-weight: normal;
}

.icon__container {
  background-color: #8e8e8e2b;
  border-radius: 100%;
  padding: 5px;
  color: black;
  margin-left: 5px;
}

.card__selection {
  background-color: #8e8e8e2b;
  color: black;
  padding: 1em;
  border-radius: 5px;
}

.card__selection h6 {
  font-weight: bold;
  margin-bottom: 0;
}

.vertical-line {
  height: 2em;
  right: 50%;
  width: 50%;
  border-right: 1px dashed rgba(70, 70, 70, 0.28);
  margin-top: 5px;
  margin-bottom: 5px;
}

.card__selection table th {
  color: black;
  border: none;
  font-size: 1em;
}

.card__selection table tr {
  border-bottom: 1px solid #cbcbcb;
}

.card__selection table td {
  border: none;
}

.card__selection span {
  border: none;
}

.card__deliveries table th {
  color: black;
  border: none;
  font-size: 1em;
}

.card__deliveries table tr {
  border-bottom: 1px solid #cbcbcb;
}

.card__deliveries table td {
  border: none;
}

button.bg-success-secondary {
  background-color: #28a74640;
  border: none;
  color: #28a745;
  font-weight: bold;
}

button.bg-primary-secondary {
  background-color: #7066f938;

  color: #7166f9;
  font-weight: bold;
}

.tab-menu {
  position: absolute;
  bottom: 0;
  border: none;
  left: 0;
  right: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  border-top: 1px dashed rgba(70 70 70 0.28);
  padding-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: white;
}

.tab-menu li {
  margin-bottom: 5px !important;
}

.tab-menu li > a.nav-link {
  border-radius: 5px;
  border: none;
  color: black;
  margin-left: 3px;
  margin-right: 3px;
  background-color: #8e8e8e2b;
}

.tab-menu li > a.nav-link.active {
  border: 1px solid black;
  background-color: black;
  color: white;
}

.info-legend {
    padding: 6px 8px;
    background: white;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    bottom: 55px;
    max-width: 200px;
    word-break: break-word;
}

.legend-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.legend-container>div {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: baseline;
            align-items: baseline;
}

.hide-control {
    display: none;
}
.sidebar_close_button {
  color: black;
}

.sidebar {
  box-shadow: rgba(0, 0, 0, 0.03) 1px 1px 4px !important;
}

.sidebar .nav-link {
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  vertical-align: middle;
  padding: 1rem 1rem;
  color: black;
  cursor: pointer;
}

.sidebar .nav-link > span {
  margin-left: 20px;
}

.sidebar .nav-link > svg {
  color: #d0d0d0;
}

.sidebar .nav-link.active {
  background-color: #f8f8f9;
  color: black;
  border-right: 2px solid black;
}

.sidebar .nav-link.active > svg {
  color: black;
}

.sidebar .nav-item > * {
  transition: -webkit-transform 0.25s ease;
  transition: transform 0.25s ease;
  transition: transform 0.25s ease, -webkit-transform 0.25s ease;
}

.sidebar .nav-item:hover > * {
  -webkit-transform: translateX(5px);
          transform: translateX(5px);
}

.sidebar .collapse-menu {
  background: black;
  margin: 10px;
  border-radius: 0.5rem;
  color: white;
  list-style: none;

  padding: 10px;
}

.sidebar .collapse-menu .nav-link {
  border-radius: 0.25rem;
  color: white;
  margin-bottom: 3px;
}

.sidebar .collapse-menu .nav-link > svg {
  border-radius: 0.25rem;
  color: white;
}

.sidebar .collapse-menu .nav-link.active {
  background-color: #f8f8f9;
  color: black;
  border-right: none;
}

.sidebar .collapse-menu .nav-link.active > svg {
  color: black;
}

.container-nav {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: auto;
  font-weight: 600 !important;
  background-color: white;

  margin-top: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
}

.toggler-user-menu {
  padding: 3px !important;
  line-height: 15px;
  border-radius: 5px;
  background-color: white;
  border: none;
  color: black;
}

.button_menu {
  background-color: white;
  color: black;
}

.button_menu:hover,
.toggler-user-menu:hover {
  background-color: black;
  color: white;
}

.container-nav .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: black;
}

.container-nav a {
  color: black;
  text-decoration: none;
  background-color: transparent;
}

.container-nav a:hover {
  color: #7166f9;
  text-decoration: none;
  background-color: transparent;
}

.SubjectsSimpleCard_card_subjects__Qw3ea {
  border-radius: 5px;
}

.SubjectsSimpleCard_card_subjects__body__3EPcN {
  height: 8rem;
  padding: 15px 5px;
}

.SubjectsSimpleCard_card_subject__descriptions__3C309 {
  -webkit-flex: 0.9 1;
          flex: 0.9 1;
}

.SubjectsSimpleCard_card_subject__business_name__3O5-z {
  font-size: 1em;
  font-weight: bold;
}

.SubjectsSimpleCard_card_subject__info__1uLPD {
  font-size: 0.8em;
}

.dropzone {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.card_login {
  max-width: 500px;
  padding: 10px;
  margin: 0 30px;
}

.card_login .card-header h3:after {
  display: block;
  position: absolute;
  content: "";
  background-color: #7166f9;
  height: 5px;
  width: 30px;
  margin-top: 5px;
  border-radius: 10px;
}

.header_login {
  background-color: #7166f9;
  height: 100%;
  -webkit-flex: 0.4 1;
          flex: 0.4 1;
}

.form_login {
  -webkit-flex: 0.6 1;
          flex: 0.6 1;
}

@media only screen and (max-width: 576px) {
  .card_login {
    margin-top: -20rem;
  }
}

