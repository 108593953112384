.pill {
  border: 2px solid var(--primary);
  color: var(--primary);
  padding: 5px 15px;
  border-radius: 10rem;
  margin-right: 10px;
  font-size: 0.8rem;
}

.pill.success {
  border: 2px solid var(--success);
  color: var(--success);
}

.pill.active {
  background-color: var(--primary);
  color: white;
}
