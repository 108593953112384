.card_login {
  max-width: 500px;
  padding: 10px;
  margin: 0 30px;
}

.card_login .card-header h3:after {
  display: block;
  position: absolute;
  content: "";
  background-color: #7166f9;
  height: 5px;
  width: 30px;
  margin-top: 5px;
  border-radius: 10px;
}

.header_login {
  background-color: #7166f9;
  height: 100%;
  flex: 0.4;
}

.form_login {
  flex: 0.6;
}

@media only screen and (max-width: 576px) {
  .card_login {
    margin-top: -20rem;
  }
}
